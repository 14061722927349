import { getColor } from '../../../ReassignAppointmentsStepper/data';
import SchedulerInvoker from '../../../../api/SchedulerInvoker';
import _ from 'lodash';
import config from '../../../../config/config';
import moment from 'moment';
import { useState } from 'react';
import AppointmentApiInvoker from '../../../../api/AppointmentApiInvoker';

const dateTimeSmallFormat = config.getDateTimeSmallFormat();

const useCalendarStep = (clearInformation, handleChange, handleNext, showSnackbarNotification, values, t) => {
  const { 
    bookingsError, 
    oevents, 
    practicesType, 
    professionalsSelected, 
    schedulesSelectedData, 
    events, 
    bookingChanged, 
    currentEvent
  } = values;

  const [openDetail, setOpenDetail] = useState(false);
  const [updateErrors, setUpdateErrors] = useState(null);
  const [status, setStatus]  = useState(null);

  const formatPracticeName = (practiceTypeId, genericName) => {
    const result = practicesType.filter(pt => pt.practiceTypeId === practiceTypeId);
    if (result.length > 0) {
      return result[0]['name'];
    } else {
      return genericName;
    }
  }
  const convertTimeToArray = (timeString)=> {
    const timeRegex = /^(\d{1,2}):(\d{2})\s?(am|pm)$/i;
    const match = timeString.match(timeRegex);

    if (!match) {
        throw new Error('Formato de tiempo inválido');
    }

    let hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const period = match[3].toLowerCase();

    if (period === 'pm' && hours !== 12) {
        hours += 12;
    } else if (period === 'am' && hours === 12) {
        hours = 0;
    }

    return [hours, minutes];
  }

  const getDuration = (startDate, endDate)=> {
    const start = moment(startDate);
    const end = moment(endDate);
    const differenceInMinutes = end.diff(start, 'minutes');
    
    return Math.abs(differenceInMinutes);
  }
  const addBooking = (book, id) => !book.includes(id) && book.push(id);

  const reduceBookingsOk = (dt, book) => {
    return dt.reduce((prev, parent) => {
      const bookingData = dt.map((e) => {
        addBooking(book, parent.appointmentId);
        return {
          id: e.schedule.transferScheduleId,
          allDay: false,
          title: `${moment(e.schedule.startDateTime).format('hh:mm a')} - ${e.label || 'Traslado'}` ,
          time_display: `${moment(e.schedule.startDateTime).format('hh:mm a')} - ${moment(e.schedule.endDateTime).format('hh:mm a')}`,
          time_start: moment(e.schedule.startDateTime).format('hh:mm a')|| '',
          start: new Date(e.schedule.startDateTime),
          end: new Date(e.schedule.endDateTime),
          color: getColor(e)|| '',
          employeeId: e.schedule.driver.personId|| '',
          practiceTypeId: 1,
          appointmentBookingId: parent.appointmentId|| '',
          status: !e.status ? 'OK' : e.status,
          address:e.schedule.address,
          companyPartnerShip:e.schedule.transferItemRequestId,
          estimatedDuration:getDuration(e.schedule.startDateTime,e.schedule.endDateTime),
          scheduleDateTime: e.schedule.startDateTime|| '',
          startDateTime:convertTimeToArray(moment(e.schedule.startDateTime).format('hh:mm a')),
          endDateTime:convertTimeToArray(moment(e.schedule.endDateTime).format('hh:mm a')),
          customerName:e.customerFirstName + " " + e.customerLastName
        };
      });
      return prev.concat(bookingData);
    }, []);
  }

  const reduceBookingsList = (dt, book, type) => dt.reduce((prev, parent) => {
    const dataFilter = (f) => type === 'OK' ? (f.status != 'ERROR') : (f.status === 'ERROR');
    const bookingData = dt.filter(f => dataFilter(f)).map((e) => {
      addBooking(book, parent.appointmentBookingId);
      return {
        customer: parent.customerFirstName + ' ' + parent.customerLastName,
        appointmentId: parent.schedule.transferScheduleId,
        scheduleId: parent.schedule.transferItemRequestId.transferItemRequestId,
        scheduleDateTime: moment(parent.schedule.startDateTime).format(dateTimeSmallFormat),
        location: parent.schedule.address.geographicZone.location.name,
        geographicZone: parent.schedule.address.geographicZone.detail,
        practiceTypeName: t(`status.transfer.${parent.schedule.transferItemRequestId.transferType}`),
      };
    });
    return prev.concat(bookingData)
  }, []);

  const formatTableDataCalendar = (data) => {
    let eventsData = [];
    let bookingsOk = [];
    let bookingsErr = [];
    let notAssignedData = [];
    let AssignedDataOk = []

    if (data?.length) {
      eventsData = reduceBookingsOk(data, bookingsOk)
      notAssignedData = reduceBookingsList(data, bookingsErr, 'ERROR')
      AssignedDataOk = reduceBookingsList(data, bookingsOk, 'OK')
    }

    const oresultData = data.reduce((newArray, element) => {
      newArray.push(element);
      return newArray;
    }, []);

    handleChange('events', eventsData)
    handleChange('oevents', oresultData)
    handleChange('bookings', bookingsOk);
    handleChange('bookingsError', bookingsErr)
    handleChange('schedulesNotAssigned', notAssignedData);
    handleChange('schedulesAssigned', AssignedDataOk);
    handleChange('loadingTable', false);
  }

  const calculateReassignment = (prevStep = false) => {
    const bodySchedules = schedulesSelectedData.map((d) => {
      const scheduleDate = d.scheduleDateTimeUpdate ? d.scheduleDateTimeUpdate : d.scheduleDate;
      const scheduleTime = d.scheduleTimeUpdate ? moment(d.scheduleTimeUpdate, 'hh:mm a').format('HH:mm:ss') : moment(d.scheduleTime, 'HH:mm').format('HH:mm:ss');
      const transferScheduleDateTime = moment(
        `${scheduleDate.split(' ')[0]} ${scheduleTime}`, 
        d.scheduleDateTimeUpdate ? 'YYYY-MM-DD HH:mm:ss' : 'DD/MM/YYYY HH:mm:ss' 
      ).format('YYYY-MM-DDTHH:mm:ss');
  
      const transferScheduleDateTimeEnd = moment(
        transferScheduleDateTime, 
        'YYYY-MM-DDTHH:mm:ss'
      ).add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      return {
        startDateTime: moment(transferScheduleDateTime).format('YYYY-MM-DD HH:mm:ss.SSS'),
        endDateTime: moment(transferScheduleDateTimeEnd).format('YYYY-MM-DD HH:mm:ss.SSS'),  
        transferScheduleId: d.scheduleId,
      }
    });
    const body = { schedules: bodySchedules, drivers: professionalsSelected };
    handleChange('loadingevents', true);

    AppointmentApiInvoker.scheduleReasignDriver(body, (data) => {

        formatTableDataCalendar(data);
        handleChange('data_saved', false);
        handleChange('loadingevents', false);
        prevStep && handleNext(true);
      }, (error) => {
        const { message, statusText } = error
        const notificationMessage = typeof message === "string" ? message : statusText;
        showSnackbarNotification(notificationMessage, 'danger')
        handleChange('data_saved', false);
        handleChange('loadingevents', false)
      }
    )
  }

  const handleDeleteBookings = (bookingsDel) => {
    if (bookingsDel && bookingsDel.length > 0) {
      SchedulerInvoker.deleteBookingsByArray(bookingsDel, () => {
          handleChange('events', [])
          handleChange('bookingChanged', []);
          handleChange('bookings', [])
          handleChange('showConfirmSave', false)
        }, (error) => {
          handleChange('showConfirmSave', false);
          const { message, statusText }  = error;
          const notificationMessage = typeof message === "string" ? message : statusText;
          showSnackbarNotification(notificationMessage, 'danger');
        }
      );
      handleChange('hasChangedSchedules', false);
      handleChange('showConfirmUndo', false);
      handleChange('events', oevents);
    }
  }

  const handleUndoChanges = (bookingsDel) => {
    handleDeleteBookings(bookingsDel);
    calculateReassignment()
    showSnackbarNotification(t('common.undoMessageConfirm'), 'success')
  }

  const formatUpdateAppointment = (data, appointment) => {
    return data.forEach((b, i) => {
      if (b.id === currentEvent.id) {
        let item = appointment.bookings[0];
        data[i] = {
          id: item.scheduleBookingId,
          allDay: false,
          title: `${moment(item.scheduleDateTime).format('hh:mm a')} - ${item.label}`,
          time_display: `${moment(item.scheduleDateTime).format('hh:mm a')} - ${moment(item.scheduleEndDateTime).format('hh:mm a')}`,
          time_start: moment(item.scheduleDateTime).format('hh:mm a'),
          color: getColor(item.group),
          employeeId: item.employeeId,
          start: new Date(item.scheduleDateTime),
          end: new Date(item.scheduleEndDateTime),
          status: item.status,
          errorDetail: item.status === 'ERROR' || item.status === 'WARNING' 
            ? item.errorDetail : '',
          type: item.booking || item.scheduleBookingId
            ? 'booking' : 'schedule',
          scheduleDateTime: item.scheduleDateTime
        }
      }
    });
  }

  const updateAppointment = (appointment) => {
    let result = [...events];
    setUpdateErrors(null);
    setStatus(null);

    if (appointment?.status) {
      if (appointment.status !== 'ERROR') {
        if (appointment.appointmentBookingId) {
          const bchanged = [...bookingChanged];
          if (!bchanged.includes(appointment.appointmentBookingId)) {
            bchanged.push(appointment.appointmentBookingId)
            handleChange('bookingChanged', bchanged);
          }
        }
        formatUpdateAppointment(result, appointment);
        setOpenDetail(false)
        handleChange('events', _.orderBy(result, ['start'], ['asc']));
        handleChange('hasChangedSchedules', true)
      } else {
        setUpdateErrors(appointment.errors);
        setStatus(appointment.status)
      }
    } else {
      setUpdateErrors([{ detail: appointment.message, statusError: 'ERROR' }]);
      setStatus('ERROR')
    }
  }

  const handleOnSelectedEvent = (event) => {
    setOpenDetail(true);
    setUpdateErrors(null)
    handleChange('currentEvent', event)
  }

  const eventColors = (event) => {
    let backgroundColor = 'event-';
    backgroundColor = event.color ? `${backgroundColor}${event.color}` : `${backgroundColor}default`
    return {
      className: backgroundColor
    }
  }

  const confirmReassignment = () => {
    handleChange('busyButton', true);
    calculateReassignment()
    clearInformation()
    showSnackbarNotification(t('reassignAppointments.saveSuccess.transfer'), 'success');
    handleDeleteBookings(bookingsError);
  }

  return {
    calculateReassignment,
    confirmReassignment,
    eventColors,
    handleDeleteBookings,
    handleOnSelectedEvent,
    handleUndoChanges,
    updateAppointment,
    openDetail,
    setOpenDetail,
    updateErrors,
    status,
  }
}

export default useCalendarStep;
