import { Theme, makeStyles } from '@material-ui/core/styles';
import { grayColor, primaryColor, whiteColor } from '../../assets/components/material-dashboard-pro-react';

type StyleProps = {
  appColor: string;
};

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  gridContainer: {
    backgroundColor: whiteColor,
    padding: theme.spacing(2),
    margin: 'auto',
    color: grayColor[6],
    maxWidth: 500,
    minHeight: '100vh',
    paddingTop: theme.spacing(4),
    position: 'relative',
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(6),
    cursor: 'pointer',
  },
  closeIcon: (props) => ({
    color: props.appColor ?? primaryColor[0],
    fontSize: 24,
  }),
  header: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(7),
    textAlign: 'center',
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    color: grayColor[6],
  },
  gridItemCentered: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  contentCircularProgress: {
    paddingTop: theme.spacing(5),
  },
  modalMessage: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonContainerModal: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
  },
  buttonAccept: (props) => ({
    backgroundColor: props.appColor,
    '&:hover, &:focus': {
      backgroundColor: props.appColor,
    },
    '&:hover': {
      boxShadow: '0 14px 26px -12px rgba(93, 196, 185, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(93, 196, 185, 0.2)',
    },
  }),
  buttonCancel: (props) => ({
    color: props.appColor,
    fontWeight: 500,
    '&:hover, &:focus': {
      color: props.appColor,
      opacity: 0.8,
    },
  }),
}));