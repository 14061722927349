import { Control, Controller } from 'react-hook-form'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import { DetailInfo } from '../../interfaces/interface'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import GridItem from '../../../Grid/GridItem'
import React from 'react'
import TextareaInputWithHookForm from '../TextField/TextareaInputWithHookForm'
import clsx from 'clsx'
import { useStyles } from './styles'

interface Option {
  id: string
  label: string
  disabled?: boolean
}

interface RadioInputWithHookFormProps {
  control: Control<any>
  fieldName: string
  options: Option[]
  label?: string
  disabled?: boolean
  detailInfo?: DetailInfo
  appColor: string
}

const RadioInputWithHookForm: React.FC<RadioInputWithHookFormProps> = ({
  control,
  fieldName,
  options,
  label,
  disabled = false,
  detailInfo,
  appColor,
}) => {
  const classes = useStyles({ appColor })

  return (
    <GridItem xs={12}>
      <FormControl component="fieldset" className={classes.fieldset}>
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <Controller
          name={fieldName}
          control={control}
          render={({ field }) => (
            <>
              <RadioGroup
                {...field}
                value={field.value || ''}
                className={classes.radioGroup}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    label={option.label}
                    control={
                      <Radio
                        disabled={disabled || option.disabled}
                        icon={
                          <FiberManualRecord
                            className={clsx(
                              classes.radioIcon,
                              classes.radioUnchecked
                            )}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={clsx(
                              classes.radioIcon,
                              classes.radioChecked
                            )}
                          />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                          disabled: classes.disabledCheckboxAndRadio,
                        }}
                      />
                    }
                  />
                ))}
              </RadioGroup>

              {field.value === 'yes' && detailInfo && (
                <div className={classes.detailInfo}>
                  <TextareaInputWithHookForm
                    control={control}
                    fieldName={detailInfo.fieldName}
                    text={detailInfo.question}
                    clarification={detailInfo.clarification}
                    maxLength={detailInfo?.maxLength}
                  />
                </div>
              )}
            </>
          )}
        />
      </FormControl>
    </GridItem>
  )
}

export default RadioInputWithHookForm
