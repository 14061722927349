import React, { FC } from 'react';

import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import HeartIcon from '../../../../icons/HeartIcon/HeartIcon';
import { useStyles } from './useStyles';
import { useTranslation } from 'react-i18next';

type Props = {
  appColor: string;
};

const CompletedSurveyScreen: FC<Props> = ({ appColor }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <GridContainer className={classes.container}>
      <GridItem xs={12} className={classes.containerMessage}>
        <HeartIcon strokeColor={appColor} className={classes.heartIcon} />
        <div className={classes.contentMessage} dangerouslySetInnerHTML={{ __html: t('survey.messageSuccess') }} />
      </GridItem>
    </GridContainer>
  )
}

export default CompletedSurveyScreen;
