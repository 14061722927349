import { Theme, makeStyles } from '@material-ui/core/styles';

import { grayColor } from '../../../../assets/components/material-dashboard-pro-react';

type StyleProps = {
  appColor: string;
};

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  sectionTitle: {
    color: grayColor[6],
    fontSize: '1.2rem',
    fontWeight: 500,
    marginBottom: theme.spacing(6),
    textAlign: 'left',
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    textAlign: 'right',
    width: '100%',
  },
  button: {
    padding: theme.spacing(1, 2),
    '& span': {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        paddingLeft: theme.spacing(1),
        '&.send-icon': {
          rotate: '320deg',
        }
      },
    }
  },
  buttonNext: (props) => ({
    backgroundColor: props.appColor,
    fontWeight: 500,
    '&:hover, &:focus': {
      backgroundColor: props.appColor,
    },
    '&:hover': {
      boxShadow: '0 14px 26px -12px rgba(93, 196, 185, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(93, 196, 185, 0.2)',
    },
  })
}));
