import {
  FormKey,
  Section,
  SurveyFormData,
  SurveyResponse,
  SurveySubmission,
} from '../interfaces/interface'
import { useEffect, useState } from 'react'

import i18next from 'i18next'
import { surveyForms } from '../constants'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import config from '../../../config/config'

type UserResponses = { [key: string]: string | number | null }

export const useSurveys = (email: string, companyId: string, lang: string) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0)
  const [isNextButtonDisabled, setIsNextButtonDisabled] =
    useState<boolean>(true)
  const [personSurveyId, setPersonSurveyId] = useState<number>(0)
  const [sections, setSections] = useState<Section[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [showFinishScreen, setShowFinishScreen] = useState<boolean>(false)
  const [error, setError] = useState({
    error: false,
    title: '',
    description: '',
    souldClose: true,
  })

  const { t } = useTranslation()
  const title = t('survey.title')
  const send = t('survey.send')
  const next = t('table.next')
  const exit = t('appointment.tools.exit.tittle')
  const confirm = t('survey.button.confirm')
  const cancel = t('common.cancel')
  const messageModal = t('survey.message.confirm')
  const labelLoading = t('common.saving')
  const acceptText = t('common.accept')

  const questions = sections.flatMap((section) => section.questions)
  const defaultValues = questions.reduce((acc, question) => {
    acc[question.fieldName] = question.type === 'rating' ? null : ''
    return acc
  }, {} as { [key: string]: any })

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    getValues,
  } = useForm({ defaultValues })

  const watchAllFields = watch()

  const headers = new Headers({
    'Content-Type': 'application/json',
    'x-itlg-companyId': companyId,
    'Accept-Language': lang ?? 'en',
  })

  const handleError = (
    description: string,
    souldClose: boolean = true,
    title?: string
  ): void => {
    setError({
      error: true,
      title: title ?? t('common.error'),
      description,
      souldClose,
    })
  }

  const getSurveyData = async (email: string) => {
    const params = {
      headers,
      method: 'GET',
    }
    const response = await fetch(`/persons/survey/${email}`, params)
    if (!response.ok) {
      handleError(t('survey.error.badRequest'), true)
    }
    const data: SurveyResponse[] = await response.json()
    if (!data.length) {
      throw new Error(t('survey.error.notFound'))
    }
    return data[0]
  }

  const mapUserResponses = (surveyData: SurveyResponse): UserResponses => {
    return surveyData.responseList.reduce((acc, responseItem) => {
      const questionType = questions.find(
        (q) => q.fieldName === responseItem.codeQuestion
      )?.type
      acc[responseItem.codeQuestion] =
        questionType === 'rating'
          ? Number(responseItem.value) || null
          : responseItem.value
      return acc
    }, {} as UserResponses)
  }

  const setQuestionsValues = (userResponses: UserResponses) => {
    questions.forEach((question) => {
      const userResponse = userResponses[question.fieldName]
      setValue(
        question.fieldName,
        userResponse !== undefined ? userResponse : ''
      )

      if (question.type === 'radio' && question.detailInfo) {
        const detailResponse = userResponses[question.detailInfo.fieldName]
        setValue(
          question.detailInfo.fieldName,
          detailResponse !== undefined ? detailResponse : ''
        )
      }
    })
  }

  const searchSectionToStart = (
    matchingSections: Section[],
    userResponses: UserResponses
  ) => {
    const completedSections = matchingSections.map((section) => {
      const allQuestionsAnswered = section.questions.every((question) => {
        const value = userResponses[question.fieldName]
        if (question.type === 'rating') {
          return value !== null && value !== '' && typeof value === 'number'
        }
        if (question.type === 'radio' && question.detailInfo) {
          const detailValue = userResponses[question.detailInfo.fieldName]
          return value === 'yes'
            ? detailValue !== null && detailValue !== ''
            : true
        }
        return value !== null && value !== ''
      })
      return allQuestionsAnswered
    })
    const firstIncompleteSectionIndex = completedSections.findIndex(
      (completed) => !completed
    )
    setCurrentSectionIndex(
      firstIncompleteSectionIndex >= 0 ? firstIncompleteSectionIndex : 0
    )
  }

  const fetchResponses = async () => {
    setIsLoading(true)
    try {
      const surveyData = await getSurveyData(email)
      setPersonSurveyId(surveyData.personSurveyId)
      const matchingSections = surveyForms[surveyData.surveyVersion as FormKey]
      if (!matchingSections) {
        throw new Error(t('survey.error.notFound'))
      }
      setSections(matchingSections)
      const userResponses = mapUserResponses(surveyData)
      setQuestionsValues(userResponses)
      searchSectionToStart(matchingSections, userResponses)
    } catch (error) {
      const message =
        error instanceof Error ? error.message : t('survey.error.badRequest')
      handleError(message, true)
    } finally {
      setTimeout(() => setIsLoading(false), 1000)
    }
  }

  useEffect(() => {
    if (lang) {
      i18next.changeLanguage(lang)
    }
    const fetchData = async () => {
      await fetchResponses()
    }

    fetchData()
  }, [setValue, email, sections])

  useEffect(() => {
    if (
      !sections.length ||
      currentSectionIndex < 0 ||
      currentSectionIndex >= sections.length
    ) {
      setIsNextButtonDisabled(true)
      return
    }

    validateSection()
  }, [currentSectionIndex, sections, register, trigger, watchAllFields])

  const goToNextSection = () => {
    if (currentSectionIndex < sections.length - 1) {
      setCurrentSectionIndex((prev) => prev + 1)
    }
  }

  const validationCharacteres = (value: string) => {
    const textAreaValidation: boolean = config.regex_forms.test(value)
    const dangerousCombinations: boolean = config.dangerousCombinations.some(
      (pattern) => pattern.test(value)
    )
    let charactersValid: boolean =
      !textAreaValidation || dangerousCombinations ? true : false
    return charactersValid
  }

  const validateSection = async () => {
    const currentQuestions = sections[currentSectionIndex].questions
    const currentQuestionFieldNames = currentQuestions.map((q) => q.fieldName)
    currentQuestionFieldNames.forEach((fieldName) => {
      register(fieldName)
    })
    let isValid = await trigger(currentQuestionFieldNames)
    const currentValuesValid = currentQuestions.every((question) => {
      const value = watch(question.fieldName)

      if (question.type === 'rating') {
        return (
          value !== null &&
          value !== '' &&
          typeof value === 'number' &&
          !isNaN(value)
        )
      }
      if (value === null || value === '') {
        return false
      }
      if (question.type === 'radio' && question.detailInfo) {
        const detailValue = watch(question.detailInfo.fieldName)
        return value === 'yes'
          ? detailValue !== null &&
              detailValue !== '' &&
              !validationCharacteres(detailValue)
          : true
      }
      if (question.type === 'textarea') {
        if (validationCharacteres(value)) {
          return false
        }
      }
      return true
    })
    if (
      currentSectionIndex === sections.length - 1 &&
      isValid &&
      currentValuesValid
    ) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
    setIsNextButtonDisabled(!isValid || !currentValuesValid)
  }

  const currentProgress = ((currentSectionIndex + 1) / sections.length) * 100

  const buildDataDtoSend = (data: SurveyFormData): SurveySubmission => {
    const responseDtoList = Object.keys(data).map((key) => ({
      codeQuestion: key,
      value: data[key] ?? null,
    }))

    return {
      personSurveyId,
      responseDtoList,
      completeSurvey: isCompleted,
    }
  }

  const onSubmit = async (data: SurveyFormData): Promise<void> => {
    const dataToSend = buildDataDtoSend(data)
    const params = {
      headers,
      method: 'PATCH',
      body: JSON.stringify(dataToSend),
    }

    try {
      setIsLoading(true)
      const response = await fetch('/persons/survey/reponse', params)

      if (!response.ok) {
        handleError(t('survey.error.send'), false)
        return
      }
      await response.json()
      if (isCompleted) {
        setShowFinishScreen(true)
      } else {
        goBack()
      }
    } catch (error) {
      handleError(t('survey.error.send'), false)
      console.error('Error on data send:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleModalCancel = () => {
    setShowModal(false)
  }

  const goBack = (forceGoBack: boolean = false) => {
    const webView = (window as any).ReactNativeWebView
    if (!webView) {
      return
    }
    const messageType = forceGoBack
      ? 'goBackError'
      : isCompleted
      ? 'goBackComplete'
      : 'goBackInprogress'
    webView.postMessage(JSON.stringify({ type: messageType }))
  }

  const handleCloseOnError = (forceGoBack: boolean = false): void => {
    if (forceGoBack) {
      return goBack(forceGoBack)
    } else if (error?.error === true && error?.souldClose) {
      return goBack(error?.error)
    }
    setError({ error: false, title: '', description: '', souldClose: true })
  }

  const texts = {
    title,
    send,
    next,
    exit,
    cancel,
    confirm,
    acceptText,
    labelLoading,
    messageModal,
  }

  return {
    texts,
    currentProgress,
    sections,
    isNextButtonDisabled,
    currentSectionIndex,
    isLoading,
    showModal,
    showFinishScreen,
    isCompleted,
    control,
    error,
    getValues,
    register,
    setError,
    setShowModal,
    handleModalCancel,
    handleCloseOnError,
    handleSubmit,
    onSubmit,
    goToNextSection,
    goBack,
  }
}
