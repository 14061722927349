import React, { FC } from 'react'

import Button from '../CustomButtons/Button'
import ButtonSpinner from '../ButtonSpinner'
import { CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CompletedSurveyScreen from './components/CompletedSurveyScreen/CompletedSurveyScreen'
import CustomDialog from '../CustomDialog'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import HeartIcon from '../../icons/HeartIcon/HeartIcon'
import { Size } from '../../util'
import SurveyForm from './components/SurveyForm/SurveyForm'
import SurveyLinearProgress from './components/SurveyLinearProgress/SurveyLinearProgress'
import { primaryColor } from '../../assets/components/material-dashboard-pro-react'
import { useStyles } from './useStyles'
import { useSurveys } from './hook/useSurveys'

export interface SurveysProps {
  params: {
    email: string
  }
  location: {
    query: {
      companyId: string
      lang: string
      color?: string
    }
  }
}

const Surveys: FC<SurveysProps> = (props) => {
  const { email } = props.params
  const { color, companyId, lang } = props.location.query

  const appColor = color ?? primaryColor[0]
  const classes = useStyles({ appColor })

  const {
    texts,
    onSubmit,
    getValues,
    error,
    control,
    register,
    setShowModal,
    sections,
    currentSectionIndex,
    currentProgress,
    isLoading,
    showModal,
    showFinishScreen,
    isNextButtonDisabled,
    handleModalCancel,
    handleSubmit,
    goToNextSection,
    goBack,
    handleCloseOnError,
  } = useSurveys(email, companyId, lang)

  const {
    title,
    exit,
    confirm,
    cancel,
    messageModal,
    labelLoading,
    acceptText,
    send,
    next,
  } = texts
  return (
    <GridContainer className={classes.gridContainer}>
      <button
        className={classes.closeButton}
        onClick={() => (showFinishScreen ? goBack() : setShowModal(true))}
      >
        <CloseIcon className={classes.closeIcon} />
      </button>
      {showFinishScreen ? (
        <CompletedSurveyScreen appColor={appColor} />
      ) : (
        <GridItem xs={12} className="no-padding">
          <GridItem xs={12} className={classes.header}>
            <HeartIcon strokeColor={appColor} style={{ fontSize: 48 }} />
            <h2 className={classes.title}>{title}</h2>
          </GridItem>
          <GridItem xs={12} className={classes.gridItemCentered}>
            {isLoading ? (
              <div className={classes.contentCircularProgress}>
                <CircularProgress style={{ color: appColor }} />
              </div>
            ) : (
              <SurveyForm
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                goToNextSection={goToNextSection}
                register={register}
                control={control}
                texts={{ send, next }}
                data={{
                  sections,
                  currentSectionIndex,
                  isNextButtonDisabled,
                  color,
                }}
              />
            )}
          </GridItem>
        </GridItem>
      )}
      {sections.length > 0 && (
        <GridItem xs={12}>
          <SurveyLinearProgress
            progress={currentProgress}
            currentSectionIndex={currentSectionIndex + 1}
            totalSections={sections.length}
            appColor={appColor}
          />
        </GridItem>
      )}
      {showModal ? (
        <CustomDialog
          maxWidth="sm"
          open={showModal}
          title={exit}
          onClose={() => handleModalCancel()}
        >
          <p className={classes.modalMessage}>{messageModal}</p>
          <div className={classes.buttonContainerModal}>
            <Button
              simple
              size={Size.sm}
              onClick={() => handleModalCancel()}
              {...(color
                ? { muiClasses: { root: classes.buttonCancel } }
                : { color: 'primary' })}
            >
              {cancel}
            </Button>
            <ButtonSpinner
              onClick={() => {
                const currentData = getValues()
                onSubmit(currentData)
                handleModalCancel()
              }}
              size={Size.sm}
              labelLoading={labelLoading}
              loading={isLoading}
              label={confirm}
              typeButton="submit"
              id="button-update"
              {...(color ? { customColor: color } : {})}
            />
          </div>
        </CustomDialog>
      ) : null}
      {error?.error ? (
        <CustomDialog
          maxWidth="sm"
          open={error.error}
          title={error.title}
          onClose={() => handleCloseOnError()}
          souldClose={error.souldClose}
        >
          <p className={classes.modalMessage}>{error.description}</p>
          <div className={classes.buttonContainerModal}>
            <Button
              simple
              size={Size.sm}
              onClick={() => handleCloseOnError(true)}
              {...(color
                ? { muiClasses: { root: classes.buttonCancel } }
                : { color: 'primary' })}
            >
              {exit}
            </Button>
            <Button
              onClick={() => handleCloseOnError()}
              {...(color
                ? { muiClasses: { root: classes.buttonAccept } }
                : { color: 'primary' })}
            >
              {acceptText}
            </Button>
          </div>
        </CustomDialog>
      ) : null}
    </GridContainer>
  )
}

export default Surveys
