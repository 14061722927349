import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form'
import React, { FC } from 'react'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Button from '../../../CustomButtons/Button'
import InputFactory from '../InputFactory/InputFactory'
import { Section } from '../../interfaces/interface'
import SendIcon from '@material-ui/icons/Send'
import { primaryColor } from '../../../../assets/components/material-dashboard-pro-react'
import { useStyles } from './useStyles'

type Texts = {
  send: string
  next: string
}

type SurveyData = {
  sections: Section[]
  currentSectionIndex: number
  isNextButtonDisabled: boolean
  color?: string
}

type SurveyProps = {
  data: SurveyData
  handleSubmit: UseFormHandleSubmit<FieldValues>
  onSubmit: (data: any) => void
  goToNextSection: () => void
  register: UseFormRegister<any>
  control: any
  texts: Texts
}

const SurveyForm: FC<SurveyProps> = ({
  data,
  handleSubmit,
  onSubmit,
  goToNextSection,
  register,
  control,
  texts,
}) => {
  const { sections, currentSectionIndex, isNextButtonDisabled, color } = data
  const appColor  = color ?? primaryColor[0]
  const classes = useStyles({ appColor })

  const section = sections[currentSectionIndex]
  const sectionNumber = currentSectionIndex + 1

  const isAnotherSections = currentSectionIndex < sections.length - 1

  const propsButtonLastSection = {
    type: 'submit',
  }
  const propsButtonAnhotherSections = {
    onClick: goToNextSection,
    type: 'button',
  }
  const propsButton = isAnotherSections
    ? propsButtonAnhotherSections
    : propsButtonLastSection

  if (!sections.length) {
    return null
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div key={section?.sectionId}>
        <h3 className={classes.sectionTitle}>
          {`${sectionNumber}. ${section?.sectionTitle}`}
        </h3>
        {section?.questions.map((question) => (
          <div key={question.id}>
            <InputFactory
              type={question.type}
              control={control}
              registerResponse={register(question.fieldName, {
                required: true,
              })}
              question={question}
              clarification={question.clarification}
              options={question.options}
              detailInfo={question.detailInfo}
              appColor={appColor}
            />
          </div>
        ))}
      </div>

      <div className={classes.buttonContainer}>
        <Button
          {...propsButton}
          variant="contained"
          size="lg"
          disabled={isNextButtonDisabled}
          className={classes.button}
          {...(appColor
            ? { muiClasses: { root: classes.buttonNext } }
            : { color: 'primary' })}
        >
          <span>
            {isAnotherSections ? (
              <>{texts.next} <ArrowForwardIcon /></>
            ) : (
              <>{texts.send} <SendIcon className="send-icon" /></>
            )}
          </span>
        </Button>
      </div>
    </form>
  )
}

export default SurveyForm
