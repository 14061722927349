import { FormKey, Section } from "../interfaces/interface";

export const surveyForms: Record<FormKey, Section[]> = {
    "1.0": [
        {
            sectionTitle: 'Usabilidad del producto',
            sectionId: 1,
            questions: [
                {
                    id: 1,
                    type: 'rating',
                    question: '¿Cómo calificarías tu satisfacción con la facilidad de uso de la “App”?',
                    clarification: '(1 muy insatisfecho, 5 muy satisfecho)',
                    fieldName: 'Q1',
                },
                {
                    id: 2,
                    type: 'rating',
                    question: '¿Cuánto tiempo te llevó aprender a utilizar las funciones principales?',
                    clarification: '(1 muchísimo tiempo, 5 muy poco)',
                    fieldName: 'Q2',
                },
                {
                    id: 3,
                    type: 'rating',
                    question: '¿Qué tan útil es nuestro producto para ti?',
                    clarification: '(1 muy poco útil, 5 muy útil)',
                    fieldName: 'Q3',
                },
                {
                    id: 4,
                    type: 'textarea',
                    question: '¿Cuál es la característica o funcionalidad más importante que crees que deberíamos agregar?',
                    clarification: '',
                    fieldName: 'Q4',
                    maxLength: 500
                },
            ],
        },
        {
            sectionTitle: 'Diseño y estética',
            sectionId: 2,
            questions: [
                {
                    id: 5,
                    type: 'rating',
                    question: '¿Cómo calificarías tu satisfacción con el diseño visual de la “App”?',
                    clarification: '(1 muy malo, 5 excelente)',
                    fieldName: 'Q5',
                },
                {
                    id: 6,
                    type: 'rating',
                    question: '¿Los colores y la paleta visual son atractivos y fáciles de entender?',
                    clarification: '(1 muy poco atractivos, 5 muy atractivos)',
                    fieldName: 'Q6',
                },
            ],
        },
        {
            sectionTitle: 'Navegación',
            sectionId: 3,
            questions: [
                {
                    id: 7,
                    type: 'rating',
                    question: '¿Encuentras intuitiva la navegación por la “App”?',
                    clarification: '(1 muy poco intuitiva, 5 muy intuitiva)',
                    fieldName: 'Q7',
                },
                {
                    id: 8,
                    type: 'radio',
                    question: '¿Hubo algún momento en el que te resultó difícil encontrar lo que buscabas?',
                    clarification: '',
                    options: [
                        { id: 'yes', label: 'Sí' },
                        { id: 'no', label: 'No' },
                    ],
                    fieldName: 'Q8',
                    detailInfo: {
                        id: 9,
                        type: 'textarea',
                        question: 'Por favor contanos que fue difícil de encontrar.',
                        clarification: '',
                        fieldName: 'Q9',
                        maxLength: 500
                    },
                },

            ],
        },
        {
            sectionTitle: 'Velocidad y rendimiento',
            sectionId: 4,
            questions: [
                {
                    id: 10,
                    type: 'rating',
                    question: '¿Cuál es tu grado de satisfacción con la velocidad y rendimiento de la “App”?',
                    clarification: '(1 muy insatisfecho, 5 muy satisfecho)',
                    fieldName: 'Q10',
                },
            ],
        },
        {
            sectionTitle: 'Engagement / Satisfacción general',
            sectionId: 5,
            questions: [
                {
                    id: 11,
                    type: 'rating',
                    question: '¿Qué tan satisfecho está con su experiencia en el uso de esta “App”?',
                    clarification: '(1 muy insatisfecho, 5 muy satisfecho)',
                    fieldName: 'Q11',
                },
                {
                    id: 12,
                    type: 'rating',
                    question: '¿Qué tan probable es que recomiendes la app a otros profesionales?',
                    clarification: '(1 muy poco probable, 5 muy probable)',
                    fieldName: 'Q12',
                },
            ],
        },
        {
            sectionTitle: 'Sugerencias',
            sectionId: 6,
            questions: [
                {
                    id: 13,
                    type: 'radio',
                    question: '¿Hay algún comentario o sugerencia que pueda compartirnos para ayudarnos a mejorar tu experiencia como usuario?',
                    clarification: '',
                    options: [
                        { id: 'yes', label: 'Sí' },
                        { id: 'no', label: 'No' },
                    ],
                    fieldName: 'Q13',
                    detailInfo: {
                        id: 14,
                        type: 'textarea',
                        question: 'Por favor contanos con el mayor detalle posible tus sugerencias.',
                        clarification: '',
                        fieldName: 'Q14',
                        maxLength: 500
                    },
                },

                {
                    id: 15,
                    type: 'radio',
                    question: '¿En caso de que quisiéramos conocer más sobre tu experiencia y/o invitarte a ser parte de nuestro programa de “Beta Testers” (tester de versiones de preproducción) te gustaría que te contactáramos?',
                    clarification: '',
                    options: [
                        { id: 'yes', label: 'Sí' },
                        { id: 'no', label: 'No' },
                    ],
                    fieldName: 'Q15',
                },
            ],
        },
    ],
    "1.1": [{
        sectionId: 1,
        sectionTitle: 'Mobility',
        questions: [
            {
                id: 1,
                type: 'radio',
                question: '¿Has utilizado la App Mobility?',
                clarification: '',
                options: [
                    { id: 'yes', label: 'Sí' },
                    { id: 'no', label: 'No' },
                ],
                fieldName: 'Q1',
                detailInfo: {
                    id: 2,
                    type: 'textarea',
                    question: 'Por favor contanos con el mayor detalle posible tu uso.',
                    clarification: '',
                    fieldName: 'Q2',
                    maxLength: 4
                },
            },
            {
                id: 3,
                type: 'radio',
                question: 'Volverias a utilizarla?',
                clarification: '',
                options: [
                    { id: 'yes', label: 'Sí' },
                    { id: 'no', label: 'No' },
                ],
                fieldName: 'Q3',
            },
        ],
    },]
};
