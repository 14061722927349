import { Theme, makeStyles } from '@material-ui/core';

import { grayColor } from '../../../../assets/components/material-dashboard-pro-react';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'calc(100vh - 120px)',
    margin: theme.spacing(2, 'auto'),
    maxWidth: 400,
    padding: theme.spacing(2, 3),
  },
  containerMessage: {
    textAlign: 'center',
  },
  heartIcon: {
    fontSize: '4rem',
    marginTop: theme.spacing(2),
  },
  contentMessage: {
    color: grayColor[6],
    fontSize: '2rem',
    padding: theme.spacing(2, 5),
    '& strong': {
      display: 'block',
      fontSize: '3rem',
    },
    '& span': {
      display: 'block',
      fontSize: '1.5rem',
    },
    '& small': {
      display: 'block',
      fontSize: '2rem',
      lineHeight: 1,
      paddingBottom: theme.spacing(0.5),
      marginTop: theme.spacing(-0.5),
    },
  },
}));
