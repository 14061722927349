import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  contentInput: {
    marginBottom: '2rem',
  },
  label: {
    fontSize: '1rem',
    display: 'block',
    marginBottom: '0.5rem',
  },
  clarification: {
    fontSize: '0.875rem',
    opacity: 0.7,
    marginTop: '0.5rem',
  },
}));