import Cookies from 'universal-cookie';
import localizationEn from 'moment/locale/en-ca';
import localizationEs from 'moment/locale/es';
import localizationFr from 'moment/locale/fr';
import localizationIt from 'moment/locale/it';
import localizationPt from 'moment/locale/pt-br';
import moment from 'moment';

const config = {
    "api": {
        "intranet": "https://intranet.itlatingroup.cl:8443",
        "cloud": "https://cloud.itlatingroup.cl:8443"
    },
    "powerbi_client_id": 'fcc66094-e840-4603-8c89-bd6c54d0b2d6',
    "powerbi_scope": 'Tenant.Read.All',
    "powerbi_resource": 'https://analysis.windows.net/powerbi/api',
    "powerbi_urllogin": 'https://login.microsoftonline.com/common/oauth2/token',
    "powerbi_urlreport": 'https://api.powerbi.com/v1.0/myorg/GenerateToken',
    "regex_email": /[!#$%^&*()~\+={}[\]:;"'<>,?\/|\\]/,
    "regex_password": /[~()\-+·´=¨{}[\]:;¬"'<>,\/|\\]/,
    "regex_normal": /[!@#$~%^&*()´\_°¬'¬+·¨={}[\]:;"'<>,?\/|\\]/,
    "regex_forms": /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s.,;:!#$%^&*()_+=\-\/<>?¡¿°´'"]*$/,
    "dangerousCombinations": [
        /<script>/i,
        /<\/script>/i,
        /<div>/i,
        /<\/div>/i,
        /<img/i,
        /onerror/i,
        /\(\)\s*=>/,
        /onload\s*=/i,
        /javascript:/i,
        /\{\{[^{}]*\}\}/g,
        /<\/?([a-zA-Z]+)(\s+[a-zA-Z-]+(\s*=\s*("[^"]*"|'[^']*'|[^'">\s]+))?)*\s*\/?>/g,
      ],
    updateMoment() {
        let lang = this.getLang();
        if (lang == 'es') {
            moment.updateLocale('es', localizationEs);
        }
        if (lang == 'en') {
            moment.updateLocale('en', localizationEn);
        }
        if (lang == 'fr') {
            moment.updateLocale('fr', localizationFr);
        }
        if (lang == 'it') {
            moment.updateLocale('it', localizationIt);
        }
        if (lang == 'pt') {
            moment.updateLocale('pt', localizationPt);
        }
    },
    evaluateText(value, mask) {
        if (mask.test(value)) {
            return false;
        }
        return true;
    },
    getLang() {
        const cookies = new Cookies();
        let lang = cookies.get("lang");
        return lang == null ? "es" : lang;
    },
    getDateFormat() {
        if (this.getLang() == 'en') {
            return "MM/DD/YYYY";
        }
        return "DD/MM/YYYY";
    },
    getDateTimeFormat() {
        if (this.getLang() == 'en') {
            return 'MM/DD/YYYY hh:mm:ss a';
        }
        return 'DD/MM/YYYY hh:mm:ss a';
    },
    getDateTimeSmallFormat() {
        if (this.getLang() == 'en') {
            return 'MM/DD/YYYY hh:mm a';
        }
        return 'DD/MM/YYYY hh:mm a';
    },
    getDateToServer() {
        return 'YYYY-MM-DD';
    },
    getDateHoursMinToServer() {
        return 'YYYY-MM-DD HH:mm';
    },
    getDateTimeFormatServer() {
        return 'YYYY-MM-DD HH:mm:ss';
    }
}

export default config;
