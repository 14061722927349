import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { grayColor } from '../../../../assets/components/material-dashboard-pro-react';

type StyleProps = {
  appColor: string;
};

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    fieldset: {
      width: '100%',
    },
    checkRoot: {
      padding: theme.spacing(1),
    },
    radioRoot: {
      padding: theme.spacing(1),
    },
    checkboxAndRadio: {
      position: 'relative',
      display: 'flex',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    checkboxAndRadioHorizontal: {
      display: 'flex',
      alignItems: 'center',
    },
    radio: {
      color: (props: StyleProps) => `${props.appColor} !important`,
    },
    radioIcon: {
      width: 16,
      height: 16,
      borderRadius: '50%',
    },
    radioUnchecked: {
      color: 'transparent !important',
      border: `1px solid ${grayColor[6]}`,
    },
    radioChecked: {
      border: (props: StyleProps) => `1px solid ${props.appColor} !important`,
    },
    disabledCheckboxAndRadio: {
      '& $radioUnchecked, & $radioChecked': {
        borderColor: grayColor[6],
        opacity: 0.26,
        color: grayColor[6],
      },
    },
    radioGroup: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    detailInfo: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
    },
  }));
