import { Control, useController } from 'react-hook-form'
import React, { useState } from 'react'
import GridContainer from '../../../Grid/GridContainer'
import GridItem from '../../../Grid/GridItem'
import { TextField } from '@material-ui/core'
import config from '../../../../config/config'
import { grayColor } from '../../../../assets/components/material-dashboard-pro-react'
import { useTranslation } from 'react-i18next'

interface TextareaInputProps {
  control: Control<any>
  fieldName: string
  text: string
  disabled?: boolean
  errorText?: string
  invalid?: boolean
  clarification?: string
  maxLength?: number
}

const TextareaInputWithHookForm: React.FC<TextareaInputProps> = ({
  control,
  fieldName,
  text,
  disabled = false,
  errorText,
  invalid = false,
  clarification,
  maxLength,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  })
  const { t } = useTranslation()
  const [localErrorText, setLocalErrorText] = useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    onChange(inputValue)
    if (
      !config.regex_forms.test(inputValue) ||
      config.dangerousCombinations.some((pattern) => pattern.test(inputValue))
    ) {
      setLocalErrorText(t('error.validate.format'))
      return
    }
    if (maxLength && inputValue.length > maxLength) {
      setLocalErrorText(t('error.validate.maxLength', { maxLength }))
      return
    }
    setLocalErrorText(null)
  }

  return (
    <GridContainer style={{ justifyContent: 'center' }}>
      <GridItem xs={12} className="no-padding">
        <TextField
          multiline
          fullWidth
          disabled={disabled}
          error={invalid || !!localErrorText}
          placeholder={text}
          minRows={4}
          helperText={localErrorText || errorText}
          value={value}
          onChange={handleChange}
          margin="none"
          variant="outlined"
          inputProps={{
            maxLength,
            style: {
              fontSize: '16px',
              touchAction: 'manipulation',
            },
          }}
          InputLabelProps={{
            shrink: false,
          }}
          style={{
            backgroundColor: grayColor[12],
          }}
        />
        {clarification && (
          <p
            style={{ fontSize: '0.875rem', opacity: 0.7, marginTop: '0.5rem' }}
          >
            {clarification}
          </p>
        )}
      </GridItem>
    </GridContainer>
  )
}

export default TextareaInputWithHookForm
