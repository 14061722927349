import { Theme, makeStyles } from '@material-ui/core';

import { colorHexToRgb } from '../../../../handlers/colorHexToRgb';
import { grayColor } from '../../../../assets/components/material-dashboard-pro-react';

type StyleProps = {
  appColor: string;
};

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  contentProgress: {
    marginTop: 10,
    width: '100%',
    maxWidth: 500,
  },
  label: {
    color: grayColor[6],
    fontSize: '1rem',
    paddingLeft: 5,
  },
  customLinearProgress: (props) => ({
    marginTop: theme.spacing(1),
    marginBottom: 40,
    width: '100%',
    borderRadius: 5,
    borderRight: `2px solid ${props.appColor}`,
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: `rgba(${colorHexToRgb(props.appColor)}, 0.10)`,
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: props.appColor,
      borderRadius: 5,
      borderRight: '3px solid white',
    },
  }),
}));