import { Color, Size } from '../../util/interfaces'

import Button from '../CustomButtons/Button'
import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

interface StyleProps {
  customColor?: string
}

const useStyles = makeStyles<StyleProps>(() => ({
  buttonSpinner: {
    margin: '5px 0',
    '& svg': {
      margin: 0,
    },
    '& span.labelCont': {
      display: 'inline-block',
      padding: '0 8px',
    },
  },
  customColorClass: {
    backgroundColor: (props: StyleProps) => props.customColor,
    '&:hover': {
      backgroundColor: (props: StyleProps) => props.customColor,
      opacity: 0.8,
    },
  },
  circularProgress: {
    color: 'white',
    marginLeft: 0,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
}))

interface ComponentProps {
  authority?: string
  block?: boolean
  className?: string
  color?: Color
  disabled?: boolean
  fullWidth?: boolean
  id?: string
  label: string
  labelLoading?: string
  loading?: boolean
  onClick?: () => void
  size?: Size
  typeButton?: string
  customColor?: string
}

const ButtonSpinner = ({
  block,
  className,
  disabled,
  fullWidth,
  typeButton,
  onClick,
  color,
  loading,
  label,
  labelLoading,
  size,
  id,
  authority,
  customColor,
}: ComponentProps) => {
  const classes = useStyles({ customColor })

  return (
    <Button
      id={id}
      className={clsx(classes.buttonSpinner, className, {
        [classes.customColorClass]: customColor,
      })}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="contained"
      type={typeButton}
      onClick={onClick}
      block={block}
      size={size}
      authority={authority}
    >
      {loading ? (
        <>
          <CircularProgress className={classes.circularProgress} size={18} />
          <span className="labelCont">{labelLoading}</span>
        </>
      ) : (
        label
      )}
    </Button>
  )
}

ButtonSpinner.defaultProps = {
  block: false,
  className: '',
  color: Color.primary,
  disabled: false,
  fullWidth: false,
  labelLoading: '',
  loading: false,
  onClick: null,
  typeButton: 'text',
  id: '',
}

ButtonSpinner.propTypes = {
  block: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'violet',
    'blue',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'twitter',
    'facebook',
    'google',
    'linkedin',
    'pinterest',
    'youtube',
    'tumblr',
    'github',
    'behance',
    'dribbble',
    'reddit',
    'transparent',
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelLoading: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  typeButton: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  id: PropTypes.string,
  authority: PropTypes.string,
}

export default ButtonSpinner
