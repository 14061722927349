import { Control, useController } from 'react-hook-form'
import React, { FC } from 'react'

import Box from '@material-ui/core/Box'
import CheckIcon from '@material-ui/icons/Check'
import Rating from '@material-ui/lab/Rating'
import { colorHexToRgb } from '../../../../handlers/colorHexToRgb'
import {
  primaryColor,
} from '../../../../assets/components/material-dashboard-pro-react'

interface NumberRatingProps {
  control: Control
  fieldName: string
  appColor: string
}

const NumberRating: FC<NumberRatingProps> = ({
  control,
  fieldName,
  appColor,
}) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({
    name: fieldName,
    control,
  })

  const firstBoxRadius = '20px 0 0 20px'
  const lastBoxRadius = '0 20px 20px 0'

  const getBorderRadius = (value: number): string => {
    if (value === 1) {
      return firstBoxRadius
    }
    if (value === 5) {
      return lastBoxRadius
    }
    return '0'
  }

  const renderRatingBox = (currentValue: number) => (
    <Box
      className="rating-box"
      style={{
        backgroundColor:
          currentValue === value
            ? `rgba(${colorHexToRgb(appColor)}, 0.10)`
            : 'rgba(0, 0, 0, 0.02)',
        borderColor: appColor,
        borderStyle: 'solid',
        borderLeftWidth: currentValue === 1 ? 1 : 0,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRadius: getBorderRadius(currentValue),
        padding: 5,
        width: 50,
        height: 25,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: 0,
      }}
    >
      {currentValue === value && (
        <CheckIcon
          style={{
            marginRight: 5,
            color: appColor ?? primaryColor[0],
            fontSize: 20,
          }}
        />
      )}
      <span
        style={{
          color: currentValue === value ? appColor ?? primaryColor[0] : '#555',
          fontSize: '0.875rem',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {currentValue}
      </span>
    </Box>
  )

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Rating
        name={fieldName}
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue === value ? value : newValue)
        }}
        onBlur={onBlur}
        max={5}
        getLabelText={(currentValue) => currentValue.toString()}
        IconContainerComponent={({ value: currentValue }) =>
          renderRatingBox(currentValue)
        }
        emptyIcon={<Box></Box>}
      />
    </Box>
  )
}

export default NumberRating
