import React, { FC } from 'react'

import { LinearProgress } from '@material-ui/core'
import { useStyles } from './useStyles'

type Props = {
  currentSectionIndex: number
  totalSections: number
  progress: number
  appColor: string
}

const SurveyLinearProgress: FC<Props> = ({
  currentSectionIndex,
  totalSections,
  progress,
  appColor,
}) => {
  const classes = useStyles({ appColor })
  return (
    <div className={classes.contentProgress}>
      <p className={classes.label}>
        {currentSectionIndex} / {totalSections}
      </p>

      <LinearProgress
        variant="determinate"
        value={progress}
        className={classes.customLinearProgress}
      />
    </div>
  )
}

export default SurveyLinearProgress
